/**
 * Node JS module for WCMPG WooCommerce Block
 * 
 * Compile :
 * 
 * npm init -y
 * npm install webpack webpack-cli --save-dev
 * npm install @wordpress/scripts
 * npm install @woocommerce/dependency-extraction-webpack-plugin
 * npx webpack
 */

import { sprintf, __ } from '@wordpress/i18n';
import { registerPaymentMethod } from '@woocommerce/blocks-registry';
import { decodeEntities } from '@wordpress/html-entities';
import { getSetting } from '@woocommerce/settings';

const settings = getSetting( wcmpg_params['name'] + '_data', {} );

const label = decodeEntities( settings.title || 'WCMPG Payment' );
/**
 * Content component
 */
const Content = () => {
  return decodeEntities( settings.description || '' );
};
/**
 * Label component
 *
 * @param {*} props Props from payment API.
 */
const Label = ( props ) => {
  const { PaymentMethodLabel } = props.components;
  return <PaymentMethodLabel text={ label } />;
};

/**
 * WCMPG payment method config object.
 */
const wcmpg = {
  name: wcmpg_params['name'],
  label: <Label />,
  content: <Content />,
  edit: <Content />,
  canMakePayment: () => true,
  ariaLabel: label,
  supports: {
    features: settings.supports,
  },
};
registerPaymentMethod( wcmpg );
